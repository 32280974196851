import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [agents, setAgents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [message, setMessage] = useState('');
  const [newTask, setNewTask] = useState({ task_type: '', priority: '' });
  const [ollamaPrompt, setOllamaPrompt] = useState('');
  const [ollamaResponse, setOllamaResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchAgents = async () => {
    try {
      const response = await fetch('http://192.168.0.193:5000/agents');
      if (!response.ok) {
        throw new Error(`Error fetching agents: ${response.statusText}`);
      }
      const data = await response.json();
      setAgents(data);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch('http://192.168.0.193:5000/tasks');
      if (!response.ok) {
        throw new Error(`Error fetching tasks: ${response.statusText}`);
      }
      const data = await response.json();
      setTasks(data);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchAgents();
    fetchTasks();
  }, []);

  const allocateTasks = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('http://192.168.0.193:5000/allocate_tasks', {
        method: 'POST'
      });
      if (!response.ok) {
        throw new Error(`Error allocating tasks: ${response.statusText}`);
      }
      const data = await response.json();
      setMessage(data.message);
      await fetchAgents();
      await fetchTasks();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateTaskStatus = async (agentId, status) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('http://192.168.0.193:5000/update_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ agent_id: agentId, status: status })
      });
      if (!response.ok) {
        throw new Error(`Error updating task status: ${response.statusText}`);
      }
      const data = await response.json();
      setMessage(data.message);
      await fetchAgents();
      await fetchTasks();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNewTaskChange = (e) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const createNewTask = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await fetch('http://192.168.0.193:5000/create_task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newTask)
      });
      if (!response.ok) {
        throw new Error(`Error creating new task: ${response.statusText}`);
      }
      const data = await response.json();
      setMessage(data.message);
      setNewTask({ task_type: '', priority: '' });
      await fetchTasks();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOllamaPromptChange = (e) => {
    setOllamaPrompt(e.target.value);
  };

  const sendOllamaPrompt = async (agentType) => {
    setLoading(true);
    setError('');
    setOllamaResponse(''); // Clear previous response
    try {
      console.log('Sending prompt to Ollama...');
      const response = await fetch(`http://192.168.0.193:5000/ollama/${agentType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ prompt: ollamaPrompt })
      });
      console.log('Received response:', response);
      if (!response.ok) {
        throw new Error(`Error sending prompt to Ollama: ${response.statusText}`);
      }
      const data = await response.json();
      console.log('Parsed response data:', data);
      const extractedResponse = data.response?.response || ''; // Extract the string response
      console.log('Setting ollamaResponse to:', extractedResponse);
      setOllamaResponse(extractedResponse);  // Ensure this is a string
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Agent Management System</h1>
        {loading && <p>Loading...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button onClick={allocateTasks}>Allocate Tasks</button>
        {message && <p>{message}</p>}
        <h2>Agents</h2>
        <ul>
          {agents.map(agent => (
            <li key={agent.agent_id}>
              {agent.agent_type} (ID: {agent.agent_id}) - {agent.available ? 'Available' : 'Busy'}
              {agent.current_task && (
                <div>
                  <p>Current Task: {agent.current_task.task_type} (Priority: {agent.current_task.priority})</p>
                  <button onClick={() => updateTaskStatus(agent.agent_id, 'completed')}>Mark as Completed</button>
                </div>
              )}
            </li>
          ))}
        </ul>
        <h2>Tasks</h2>
        <ul>
          {tasks.map(task => (
            <li key={task.task_id}>
              {task.task_type} (Priority: {task.priority}) - Status: {task.status}
            </li>
          ))}
        </ul>
        <h2>Create New Task</h2>
        <form onSubmit={createNewTask}>
          <label>
            Task Type:
            <input type="text" name="task_type" value={newTask.task_type} onChange={handleNewTaskChange} required />
          </label>
          <label>
            Priority:
            <input type="number" name="priority" value={newTask.priority} onChange={handleNewTaskChange} required />
          </label>
          <button type="submit">Create Task</button>
        </form>
        <h2>Interact with Ollama</h2>
        <textarea value={ollamaPrompt} onChange={handleOllamaPromptChange} placeholder="Enter your prompt here" />
        <button onClick={() => sendOllamaPrompt('design')}>Send to Design Agent</button>
        <button onClick={() => sendOllamaPrompt('develop')}>Send to Development Agent</button>
        <button onClick={() => sendOllamaPrompt('test')}>Send to Testing Agent</button>
        <button onClick={() => sendOllamaPrompt('art')}>Send to Artistic Agent</button>
        {typeof ollamaResponse === 'string' && ollamaResponse && (
          <div>
            <h3>Ollama Response:</h3>
            <p>{ollamaResponse}</p>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
